import React from "react"
import PropTypes from "prop-types"
import Column from "./column"
import TextBlock from "./text-block"

const Message = ({ title, image, children }) => (
    <section className="message">
        <div className="message__container container">
            <Column classes={['column--copy']}>
                {image &&
                    <div className="message__image">
                        {image}
                    </div>
                }
                <TextBlock title={title} level={1} classes={['message__content']}>
                    {children}
                </TextBlock>
            </Column>
        </div>
    </section>
)

Message.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.element
}

export default Message
