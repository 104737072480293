import React from "react"
import { default as Link } from "../components/link"

import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import Message from "../components/message"
import BrandName from "../components/brand-name"

import Icon from "../images/icon-package.svg"

export default () => (
    <Layout>
        <SEO title="Jetzt bestellen" />
        <Message title="Bestellen" image={<img src={Icon} alt="Grafik: Paket" />}>
            <p>Sie können <BrandName /> über Ihren Diensleister vor Ort beziehen oder über unseren Partner <strong>mzd services</strong>.</p>
            <Link to="https://www.mzd-services.de" external={true} className="button button--secondary button--subtle">Weiter zu <strong>mzd services</strong></Link>
        </Message>
    </Layout>
)
